<template>
    <!-- 修改密码 -->
    <div>
        <div class="box">
            <div>登录名:<span style="font-weight: bold;color: #333333;">{{$store.state.memberInfo.loginName}}</span></div>
            <el-form :model="ruleForm" class="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-position="left">
                <el-form-item label="原密码" prop="oldPwd">
                    <el-input v-model="ruleForm.oldPwd" type="password" placeholder="请输入原密码"></el-input>
                </el-form-item>
                <el-form-item label="请设置密码" prop="newPwd">
                    <el-input v-model="ruleForm.newPwd" type="password" placeholder="请输入新密码"></el-input>
                </el-form-item>
                <el-form-item label="请确认密码" prop="comfirmPwd">
                    <el-input v-model="ruleForm.comfirmPwd" type="password" placeholder="请确认新密码"></el-input>
                </el-form-item>
            </el-form>
            <div>
                <div style="margin-top:20px;width:700px">
                    <el-button class="next-btn" @click="reset">重置</el-button>
                    <el-button class="register-btn" @click="submitForm">提交</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ChangePassword',
    data() {
        return {
            ruleForm: {
                newPwd: '',
                oldPwd: '',
                comfirmPwd: '',

            },
            rules: {
                newPwd: [
                    { required: true, message: '请设置新密码', trigger: 'blur' },
                ],
                comfirmPwd: [
                    { required: true, message: '请确认密码', trigger: 'blur' },
                    {
                        validator: this.validateComfirmPwd, trigger: 'blur'
                    }
                ],

                oldPwd: [
                    { required: true, message: '请输入原密码', trigger: 'blur' },

                ],
            }
        };
    },
    created() {

    },
    mounted() {

    },
    methods: {
        submitForm() {
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    const res = await this.$api.changePassword({
                        originalPassword: this.ruleForm.oldPwd,
                        password: this.ruleForm.newPwd
                    })
                     if (res.data.code === 200) {
                        this.$message({
                            message: '修改成功',
                            type: 'success'
                        });
                        this.reset();
                     }
                } else {

                    return false;
                }
            });
        },
        reset(){
            this.$refs.ruleForm.resetFields();
        },
        validateComfirmPwd(rule, value, callback) {
            if (value === '') {
                callback(new Error('请输入新密码'));
            } else {
                if (this.ruleForm.comfirmPwd !== this.ruleForm.newPwd) {
                    callback(new Error('两次密码不一致'));
                } else {
                    callback();
                }

            }
        }
    }
};
</script>
<style lang="scss" scoped>
.box {
    background: #ffffff;
    padding: 22px 25px 12px;
    font-size: 14px;
    height: 418px;
    // box-sizing: border-box;
}
.register-btn {
    background: #1a9dff;
    color: #fff;
    width: 345px;

    border-radius: 0 !important;

    margin-left: 10px;
    &:hover {
        background: #337cff;
    }
}
.next-btn {
    background: #fff;
    color: #333;
    width: 345px;

    border-radius: 0 !important;

    &:hover {
        color: #1a9dff;
        border-color: #1a9dff;
    }
}
</style>
<style lang="scss">
.ruleForm {
    .el-form-item__content {
        margin: 0 !important;
    }
    .el-form-item {
        margin-bottom: 10px !important;
        display: flex;
        flex-direction: column;
    }
    .el-input {
        width: 700px;
        .el-input__inner {
            border-radius: 0 !important;
        }
    }
}
</style>